.top-bar, .coin-his{
  padding: 0px auto !important;
  padding-top: 32px;
}

.coin-level-card{
  background-image: linear-gradient(180deg, #5586F3 0%, #55C3F5 100%);
  border-radius: 4px;
  width: 80px;
  height: 80px;
  padding: 27px 0px;
  margin-right: 20px;
  p{
    color: #FFFFFF;
    font-family: Rubik;
    font-size: 12px;
    line-height: 14px;
  }
}

.coin-converted-history{
  background-color:#FFFFFF ;
  border: 0.5px solid #E8E8E8;
  padding: 15px;
}

.exchangeInfoContainer{
  border: none !important;
}

.coin-level-name span{
    color: #464646;
    font-family: Rubik;
    font-size: 16px;
    line-height: 18px;
    text-align: left;
    margin-right: 8px;
    margin-bottom: 10px;
    display: inline-block;
}
.traid{
  color: #727272;
  font-family: Rubik;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  margin-bottom: 8px;
}

.reddemed-coins {
  color: #727272;
  font-family: Rubik;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
}
.prise-coin{
  color: #236AEE;
  font-family: Rubik;
  font-size: 16px;
  line-height: 18px;
  text-align: left;
}
.coin-list-right{
  p{
    color: #727272;
    font-family: Rubik;
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    span{
      color: #DC472E !important;
    }
  }
}
.coin-redeemed{
    color: #236AEE !important;
    font-family: Rubik;
    font-size: 16px !important;
    line-height: 18.96px;
    text-align: right;
}

.coin-convert-sucess{
    color: #40BE65;
    font-family: Rubik;
    font-size: 16px;
    line-height: 18.96px;
    text-align: right;
}

.coin-convert-date{
    color: #727272;
    font-family: Rubik;
    font-size: 16px;
    line-height: 18px;
    text-align: right;
}

.fixed-height-coin{
  height: calc(100vh - 250px);
  overflow-y: scroll !important;
}

.coin-conver-view{
  color: #236AEE;
  font-family: Rubik;
  font-size: 14px;
  line-height: 12px;
  text-align: right;
}

.amount-alert{
  display: none;
  color: red;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}