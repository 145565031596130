.coins-bg{
  background-color: #F9F9F9;
  border-bottom: 1px solid #E4E4E4;
}
.con-gg-coins{
  padding-left: 15px;
}

.con-gg-coins p{
  color: #232323;
  font-family: Rubik;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
}

.co-prise{
  color: #236AEE !important;
}

.coin-card{
  border-radius: 16.5px;
  padding:25px 15px;
}

.blue-coin-card{
  background-image: linear-gradient(180deg, #5586F3 0%, #55C3F5 100%);
}

.green-coin-card{
background-image: linear-gradient(180deg, #52A958 0%, #9AC73C 100%);
}
.pink-coin-card{
  background-image: linear-gradient(180deg, #A473F3 0%, #F099CF 100%);
}
.sucess-coin-card{
  background-image: url(../../../src/Cashback/images/sucess.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.coin-card-title{
  padding-right: 10px;
  text-align: left;
}

.coin-card-title p{
  color: #fff;
}

.wallet-ico{
  width: 50px;
  height: 50px;
  margin: 15px 0px;
}
.drop-card{
    color: #236AEE;
    font-family: Rubik;
    font-size: 16px;
    line-height: 18.96px;
}

.drop-border{
  background-color: #FFFFFF;
  border: 1.98px solid #5586F3;
  border-radius: 21.78px;
  width: 100%;
  height: 170px;
}

.coin-sucess-title p{
  color: #000;
  font-weight: 500;
}
.coin-sucess-title small{
    color:#CBCBCB;
}

.dropcard-drop svg{
  color: #236AEE;
}

@media (max-width: 468px){
  .gg-coins-img{
    width: 40px;
    height: 40px;
  }
  .con-gg-coins p{
    font-size: 11px;
    margin: 0px;
  }
}


.water{
    width:60px;
    height: 60px;
    background-color: skyblue;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    transform: rotate(270deg);
    margin: 50px auto;
    z-index: -1;
}
.water:before, .water:after{
    content:'';
    position: absolute;
    width:100px;
    height: 400px;
    top:-150px;
    background-color: #fff;
}
.water:before{
    border-radius: 45%;
    background:rgba(255,255,255,.7);
    animation:wave 5s linear infinite;
}
.water:after{
    border-radius: 35%;
    background:rgba(255,255,255,.3);
    animation:wave 5s linear infinite;
}
#newId, #second, #third, #forth, #fifth, #six{
    position: relative;
    top: -144px;
}

.coin-his{
  color: #236AEE;
  font-family: Rubik;
  font-size: 18px;
  text-align: left;
  cursor: pointer;
}

.reddme-detail{
  color: #236AEE !important;
  font-family: Rubik;
  font-size: 12px !important;
  line-height: 14px;
  text-align: center;
  margin-top: 10px;
}

.coin-card-title{
  p{
    svg{
      font-size: 16px;
      margin-left: 15px;
    }
  }
}
@keyframes wave{
    0%{
        transform: rotate(60deg);
    }
    // 25%{
    //     transform: rotate(120deg);
    // }
    // 50%{
    //       transform: rotate(180deg);
    //   }
    100%{
        transform: rotate(360deg);
    }
}