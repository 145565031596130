.react-inputs-validation__select__hover-active___2z1MQ{
    background-color: #0062cc !important;
}
.referrals {
    .container {
        .cb-status-tab {
            .bp3-tabs {
                .bp3-tab-list {
                    border-bottom: none !important;
                    .bp3-tab {
                        color: white !important;
                        background-color: #91b4f6;
                        padding: 5px 50px 5px 50px;
                        border-radius: 25px 25px 25px 25px;
                    }

                    .upv-tab {
                        left: 65px;
                    }

                    .bp3-tab[aria-selected="true"] { //, .bp3-tab:not([aria-disabled="true"]):hover
                        box-shadow: inset 0 -3px 0 #236AEE;
                        background-color: #2e6aee;
                        z-index: 1;
                    }
                }
            }
        }

        .bp3-tabs {
            .bp3-tab-list {
                border-bottom: 1px solid #E8E8E8 !important;

                .bp3-tab {
                    font-family: Rubik;
                    font-size: 15px;
                    color: #727272;
                    text-align: left;
                    font-weight: 400;
                    padding: 10px;
                }

                .bp3-tab[aria-selected="true"], .bp3-tab:not([aria-disabled="true"]):hover {
                    color: #236AEE;
                }
            }
        }
        .react-inputs-validation__select__container___3jgUR, .bp3-button{
            padding: 5px 10px !important;
        }

        .bp3-button{
            border-radius: 6px;
            font-size: 14px !important;
        }
        #bp3-tab-title_TabsExample_ng, #bp3-tab-title_TabsExample_rx{
            position: relative;
        }
        #bp3-tab-title_TabsExample_ng:before{
            content: '';
            position: absolute;
            border: 2px solid #fff;
            width: 13px;
            height: 13px;
            left: 26px;
            top: 14px;
        }
        #bp3-tab-title_TabsExample_rx{
            margin-left: 17px;
        }

        #bp3-tab-title_TabsExample_rx:before{
            content: '';
            position: absolute;
            border: 2px solid #fff;
            width: 13px;
            height: 18px;
            left: 32px;
            top: 12px;
        }
    }
}

